import { AirportWithAvailability, DestinationDetail } from '@AuroraTypes';
import {
  getNumberOfAdultPassengers,
  getNumberOfChildPassengers,
  getNumberOfInfantPassengers,
} from '@Core/prices/getNumberOfPassengers';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

export const useSearchSelectionAdultsCount: () => number = () =>
  useSearchSelectionStore(({ rooms }) => getNumberOfAdultPassengers(rooms));

/**
 * Number of children in the holiday party (inclusive of infants)
 */
export const useSearchSelectionChildrenCount: () => number = () =>
  useSearchSelectionStore(({ rooms }) => getNumberOfChildPassengers(rooms));

/**
 * Number of infants in the holiday party.
 * infant is a child aged 0 or 1 years of age.
 */
export const useSearchSelectionInfantsCount: () => number = () =>
  useSearchSelectionStore(({ rooms }) => getNumberOfInfantPassengers(rooms));

export const useSearchSelectionTravellerCount: () => number = () =>
  useSearchSelectionAdultsCount() + useSearchSelectionChildrenCount();

export const useDepartureAirportsInformation: () => AirportWithAvailability[] = () => {
  const allAirports = useSearchAvailabilityStore(({ departureAirports }) => departureAirports);
  const departureAirportIds = useSearchSelectionStore(({ departureAirports }) => departureAirports);

  return departureAirportIds.reduce<AirportWithAvailability[]>((acc, airportId) => {
    const airport = allAirports.find(({ airport }) => airport.id === airportId);
    if (airport) {
      acc.push(airport);
    }

    return acc;
  }, []);
};

export const useDestinationsInformation: () => DestinationDetail[] = () => {
  const allDestinations = useSearchAvailabilityStore(({ destinations }) => destinations);
  const destinationIds = useSearchSelectionStore(({ destinationIds }) => destinationIds);

  return destinationIds.reduce<DestinationDetail[]>((acc, destinationId) => {
    const destination = allDestinations.find(({ id }) => id === destinationId);
    if (destination) {
      acc.push(destination);
    }

    return acc;
  }, []);
};
