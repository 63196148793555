import * as Sentry from '@sentry/browser';

export const addSentryBreadcrumb = async (message: string, data?: { [key: string]: any }) => {
  setTimeout(() => {
    Sentry.addBreadcrumb({
      message,
      data,
    });
  }, 0);
};
