import { Button, DropdownSelector } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useMemo, memo, FC } from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';
import { addSentryBreadcrumb } from '@Core/addSentryBreadcrumb';
import { useDate } from '@Dates/useDateHook';
import { SelectOption } from '@UX/Forms/Select/Select';

export const chevronStyles = (disabled?: boolean): SxStyleProp => ({
  color: disabled ? 'inputDisabled' : 'brand',
  cursor: disabled ? 'default' : 'pointer',
  '&:hover': {
    color: disabled ? 'inputDisabled' : 'linkHover',
  },
});

interface MonthSelectProps extends ClassNameProps {
  availability: { [key: string]: boolean };
  selectedYearMonth: string;
  onChange: (yearMonth: string) => void;
}

export const MonthSelect: FC<MonthSelectProps> = memo(
  ({ availability, selectedYearMonth, onChange, className }) => {
    const { formatMonthYear } = useDate();
    const { t } = useTranslation();

    const selectOptions: SelectOption[] = useMemo(
      () =>
        Object.entries(availability).map(([yearMonth, available]) => ({
          label: formatMonthYear(yearMonth),
          value: yearMonth,
          disabled: !available,
        })),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [availability],
    );

    const selectedOptionIndex = selectOptions.findIndex(({ value }) => value === selectedYearMonth);

    const previousMonth = useMemo(
      () =>
        selectOptions
          .filter(({ disabled }, index) => !disabled && index < selectedOptionIndex)
          .pop(),
      [selectOptions, selectedOptionIndex],
    );

    const nextMonth = useMemo(
      () => selectOptions.find(({ disabled }, index) => !disabled && index > selectedOptionIndex),
      [selectOptions, selectedOptionIndex],
    );

    const onLeftChevronClick = () => {
      if (previousMonth) {
        onChange(String(previousMonth.value));
        addSentryBreadcrumb('Previous month clicked', { value: previousMonth.value });
      }
    };

    const onRightChevronClick = () => {
      if (nextMonth) {
        onChange(String(nextMonth.value));
        addSentryBreadcrumb('Next month clicked', { value: nextMonth.value });
      }
    };

    const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(e.target.value);
      addSentryBreadcrumb('Month selected', {
        value: e.target.value,
        label: e.target?.selectedOptions?.[0]?.text,
      });
    };

    return (
      <div
        className={className}
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Button
          data-id="previous-month"
          ariaLabel={t('previousMonth')}
          onClick={onLeftChevronClick}
          icon="Actions/MovePrevious"
          variant="Outline"
          size="48"
          disabled={!previousMonth}
        />

        <DropdownSelector
          ariaLabel={t('monthDropdown')}
          variant="Light"
          size="48"
          data-id="month-select"
          options={selectOptions}
          onChange={onSelectChange}
          value={selectedYearMonth}
          selectStyles={{
            width: '100%',
            backgroundColor: 'white',
            color: 'textDefault',
            borderRadius: '8',
            paddingLeft: ['2xs', 'xs'],
          }}
          sx={{
            width: '100%',
            marginX: ['xs'],
          }}
        />

        <Button
          data-id="next-month"
          ariaLabel={t('nextMonth')}
          onClick={onRightChevronClick}
          icon="Actions/MoveNext"
          variant="Outline"
          size="48"
          disabled={!nextMonth}
        />
      </div>
    );
  },
);
